<template>
  <div>
    <search-filter
        :items="workflows"
        :isActionColumnEnabled="true"
        itemView="list"
        :sortOptions="sortOptions"
        :columns="columns"
        :show-past-years-filter="true"
        @columnClick="$event._isDisabled?activateWorkflow($event):( $router.push({ name: 'Workflow view', params: { workflowId: $event.id } }))"
        @addItem="addWorkflow"
    >
      <!--  ================= -->
      <!--  === Grid view === -->
      <!--  ================= -->
      <template v-slot:gridView="{item}">
        <card-workflow
            :workflow="item"
            @click="selectWorkflow(item)"
            @deleteWorkflow="deleteWorkflowAlert(item)"
        />
      </template>


      <!--  ================= -->
      <!--  === List view === -->
      <!--  ================= -->

      <!--      Cell contact-->
      <template #listView_cell_contact="{item}">
        {{
          item.customerCompany ? item.customerCompany._display : (item.customerIndividual ? item.customerIndividual._display : '-')
        }}
      </template>

      <!--      Actions-->
      <template #listView_cell_actions="{item}">
        <div class="text-nowrap">

          <!--          Activate-->
          <button-unarchivate
              v-if="item.isArchived"
              @click.native.stop="activateWorkflowLocal(item)"
              v-b-tooltip.hover.left="capitalize($t('Unarchive'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          View-->
          <button-view
              v-if="!item.isArchived"
              @click.native.stop="$router.push({ name: 'Workflow view', params: { workflowId: item.id }})"
              v-b-tooltip.hover.left="capitalize($t('View'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Edit-->
          <button-edit
              v-if="!item.isArchived"
              @click.native.stop="selectWorkflow(item)"
              v-b-tooltip.hover.left="capitalize($t('Edit'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <b-dropdown
              v-if="!item.isArchived"
              variant="link"
              toggle-class="p-0"
              no-caret
              right
          >
            <template #button-content>
              <button-dropdown
                  :withIcon="true"
                  :withText="false"
                  :withBorder="false"
                  size="sm"
              />
            </template>

            <!--          Duplicate -->
            <b-dropdown-item
                @click.stop="duplicateWorkflow(item)"
            >
              <icon
                  icon="copy"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('duplicate')) }}</span>
            </b-dropdown-item>

            <!--          Archivate-->
            <b-dropdown-item
                @click.stop="archivateWorkflowLocal(item)"
            >
              <icon
                  icon="box"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('Archivate')) }}</span>
            </b-dropdown-item>

            <!--          Delete -->
            <b-dropdown-item
                v-if="item.number ==null"
                @click.stop="deleteWorkflowAlert(item)"
            >
              <icon
                  icon="times"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('delete')) }}</span>
            </b-dropdown-item>

          </b-dropdown>

        </div>
      </template>

    </search-filter>

    <!-- modal workflow-->
    <modal-workflow
        :workflow.sync="currentWorkflow"
        :title="workflowModalTitle"
        :isOpen.sync="workflowModalShow"
        @submitValidatedWorkflow="submitValidatedWorkflowLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import {capitalize} from '../../utils/filter'

import mixinWorkflows from './mixinWorkflows'
import CardWorkflow from '@/components/card/Workflow'
import useAPI from '@/utils/useAPI'
import i18n from '@/libs/i18n'
import ButtonUnarchivate from '@/components/button/Unarchivate'
import ButtonEdit from '@/components/button/Edit'
import ButtonDropdown from '@/components/button/Dropdown'
import ButtonView from '@/components/button/View'
import SearchFilter from '../../components/searchFilterV2/SearchFilter'

export default {
  components: {
    SearchFilter,
    CardWorkflow,
    ButtonUnarchivate,
    ButtonEdit,
    ButtonDropdown,
    ButtonView,
  },
  mixins: [mixinWorkflows],
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const sortOptions = ref([
      {
        text: capitalize(i18n.tc('company')),
        key: '_filter_isCustomerCompany',
      },
      {
        text: capitalize(i18n.tc('individual')),
        key: '_filter_isCustomerIndividual',
      },
      {
        text: capitalize(i18n.t('archived')),
        key: '_filter_isArchived',
      },
      {
        text: capitalize(i18n.t('active')),
        key: '_filter_isActive',
        selected: true
      },
      {
        text: capitalize(i18n.t('myWorkflows')),
        key: '_filter_myWorkflows',
      },
      {
        text: capitalize(i18n.t('myAssociatedWorkflows')),
        key: '_filter_myAssociatedWorkflows',
      },
    ])

    const columns = ref([
      {
        display: capitalize(i18n.t('opportunity')),
        key: 'opportunity',
        sortable: true
      },
      {
        display: capitalize(i18n.t('opportunityDate')),
        key: 'opportunityDate',
        isDate: true,
        sortable: true
      },
      {
        display: capitalize(i18n.t('contact')),
        key: 'contact',
        sortable: true
      },
      {
        display: capitalize(i18n.tc('lastModification', 2)),
        key: '_lastModifications',
        sortable: true
      },
    ])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { workflows } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------

    return {
      // Components

      // Data
      sortOptions,
      columns,

      // Computed
      workflows,

      // Methods
      // selectWorkflow,

    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>